:root {
  --b-primary: #60034c;
  --b-secondary: #e58b76;
  --b-grey: #575b66;

  --white: #ffffff;
  --black: #000000;

  --f-navyblue: #434e6c;
  --f-grey: #666666;

  --bg-primary: #efe6ed;
  --bg-secondary: #fcf3f1;
  --bg-grey: #f2f4f6;

  --border: #dfe4e9;
  --d-border: #bdc2c7;
  --input: #f1f1f1;
  --input: #f1f1f1;

  --Green: #38bb55;
  --Orange: #ffa654;
  --Error: #ed6663;
  --Blue: #54c1ff;

  --Primary-Bg: #f2f4f6;
  --Navy-Blue: #16244a;
  --Primary: #60034c;
  --White: #ffffff;
  --Secondary: #e58b76;
  --Black: #000;
  --Grey: #666666;
  --Green: #38bb55;
  --Orange: #ffa654;
  --Error: #ed6663;
  --Blue: #54c1ff;
  --bg-gray: #f5f5f5;
  --bg-primary: #efe6ed;
  --bg-secondary: #fcf3f1;
  --Light-Navy-Blue: #434e6c;
  --form-label-size: 12px;
  --form-text-size: 14px;
  --form-border-radius: 7.5px;
  --button-border-radius: 32px;
  --Bgsecondary: #fbece6;
}

$breakpoint-tablet: 768px;
$breakpoint-mobile: 480px;

* {
  box-sizing: border-box;
}

body {
  // background-color: var(--Primary-Bg);
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}
textarea{
  font-family: "Inter", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
  margin: 0;
}

a,
button {
  cursor: pointer;
}

iframe {
  display: none;
}
.mr16{
  margin-right: 16px;
}
.mt32{margin-top: 32px;}
.ai-btn {
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  font-size: 13px;
  border: none;
  line-height: 20px;
  font-weight: 500;
  color: var(--black);
  background: var(--border);
  width: fit-content;
  cursor: pointer;
  box-sizing: border-box;
  align-self: center;
  border: 1px solid transparent;
  white-space: nowrap;
  justify-content: center;
  text-decoration: none;

  &.round {
    width: 42px;
    min-width: 42px;
    height: 42px;
    min-height: 42px;
    padding: 0;
    align-items: center;
    justify-content: center;

    &.primary {
      background: var(--b-primary);
      border-color: var(--b-primary);
      color: var(--white);
    }

    &.secondary {
      background: var(--b-secondary);
      border-color: var(--b-secondary);
      color: var(--white);
    }

    &.blue {
      background: var(--f-navyblue);
      border-color: var(--f-navyblue);
      color: var(--white);
    }

    &.white {
      background: var(--white);
      border-color: var(--white);
      color: var(--white);
    }

    &.small {
      width: 36px;
      height: 36px;
      min-width: 36px;
      min-height: 36px;
      box-sizing: border-box;
      font-size: 18px;
    }
  }

  &.small {
    padding: 8px 16px;
    font-size: 12px;
    line-height: 14px;

    img {
      max-width: 14px;
    }
  }

  &.ex-small {
    padding: 4px 8px;
    font-size: 12px;
    line-height: 12px;

    img {
      max-width: 12px;
    }
  }

  &.line {
    background: none;

    &.primary {
      border-color: var(--b-primary);
      color: var(--b-primary);
    }

    &.secondary {
      border-color: var(--b-secondary);
      color: var(--b-secondary);
    }

    &.blue {
      border-color: var(--f-navyblue);
      color: var(--f-navyblue);
    }

    &.white {
      background: var(--white);
      border-color: var(--white);
      color: var(--white);
    }
  }

  &.plain {
    border: none;
    padding: 0;
    background: none;
  }

  &.solid {
    color: var(--white);
    &.primary {
      background: var(--b-primary);
      border-color: var(--b-primary);
      color: var(--white);
    }

    &.secondary {
      background: var(--b-secondary);
      border-color: var(--b-secondary);
      color: var(--white);
    }

    &.blue {
      background: var(--f-navyblue);
      border-color: var(--f-navyblue);
      color: var(--white);
    }

    &.white {
      background: var(--white);
      border-color: var(--white);
      color: var(--white);
    }

    &.red {
      background: var(--Error);
      border-color: var(--Error);
      color: var(--white);
    }
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  img,
  svg {
    display: inline-block;
    vertical-align: middle;
    height: 24px;
    margin: -4px 0;
  }
}

.ai-toggle-ttl {
  display: flex;
  padding: 8px 16px;  
  border-bottom: 1px var(--d-border) solid;
  font-weight: 500;
  cursor: pointer;
  font-size: 15px;
  background: var(--bg-gray);
  .tgltitle{
    flex: 1;
    padding-left: 16px;
  }
}
.ai-toggle-con {
  border: 1px var(--d-border) solid;
  border-radius: 4px;
  overflow: hidden;
  .subnavbar-menu{
    padding: 8px 16px 0;
  }
}

.table_holder img {
  width: auto;
  max-width: 100%;
}


.sname_field,
.sname_field:focus,
.sname_field:hover {
  outline: none;
  border: none;
  padding: 0;
  text-align: left;
  color: #60034c;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 14px;
  width: 100%;
  background: transparent;
  border-bottom: 1px solid #989898;
}



.ai-link {
  text-decoration: none;
  color: var(--b-secondary);
  text-decoration: underline;
  font-size: 12px;
  font-weight: 700;
  background: none;
  padding: 0;
  border: none;
}

.ai-column {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

.ai-row {
  column-gap: 20px;
  display: flex;
  width: 100%;
}

.ai-align-left {
  justify-content: flex-start;
  align-content: flex-start;
}

.ai-align-center {
  text-align: center;
}

.ai-align-right {
  justify-content: flex-end;
  align-content: flex-end;
}

.v-middle {
  align-items: center;
  justify-content: center;
}
.formdata{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.react-datepicker-wrapper{
  input{
    background: #f1f1f1;
    border-radius: 4px;
    border: none;
    color: var(--Black);
    box-sizing: border-box;
    border: none !important;
    width: 100% !important;
    margin: 0;
  }
  svg{
    right: 10px !important;
  }
}
.smalldate .react-datepicker-wrapper input{
  height: 31px;
}
.ai-form-group {
  margin-bottom: 12px;
  display: flex;
  width: 100%;
  flex-direction: column;

  label {
    color: var(--f-grey);
    display: block;
    font-size: 12px;
  }

  textarea {
    min-height: 100px;
  }

  .ai-form-value-mui {
    margin-top: 10px;
  }
  .ai-form-value {
    margin-top: 10px;
  }
  .ai-datepicker {
    border: none !important;
    color: var(--f-navyblue);
    font-size: var(--form-text-size);
    margin-top: 10px;
    padding: 18px 20px;
    width: 100%;
    box-sizing: border-box;
  }
  .react-datepicker-wrapper{
    input{
      width: 100% !important;
    }
    svg{
      right: 10px !important;
    }
  }
}
.ai-form-value {
  background: #f1f1f1;
  padding: 16px;
  border-radius: 4px;
  border: none;
  color: var(--Black);
  width: 100%;
  box-sizing: border-box;
  &.small{
    padding: 8px 16px;
  }

  &:focus {
    outline: none;
  }

  .ai-form-wrapper .ai-button-row {
    margin-top: 40px;
  }

  .ai-form-wrapper .ai-button-row {
    margin-top: 40px;
  }
}

/* Sign up */

.ai-v-center {
  display: flex;
  align-items: center;
}

.ova {
  overflow: auto;
}

.flex1 {
  flex: 1;
}

.ai-container .ai-login-container .ia-footer {
  padding: 16px;
}

.ia-footer h2 {
  margin: 0 0 10px 0;
  font-size: 12px;
  color: var(--b-primary);
}

.ia-footer p {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  margin: 0 0 4px 0;
}

.ia-footer .ia-footer-links {
  list-style: none;
  margin: 0;
  padding: 0 16px;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.ia-footer .ia-footer-links li {
  display: inline-block;
  // padding: 0 12px 0 0;
  position: relative;
}

// .ia-footer .ia-footer-links li:after {
//   content: "";
//   // position: absolute;
//   margin-top: 8px;
//   display: block;
//   right: 3px;
//   height: 6px;
//   width: 6px;
//   border-radius: 100%;
//   background-color: rgba(0, 0, 0, 0.54);
// }

.ia-footer .ia-footer-links li a {
  text-decoration: none;
  color: var(--b-primary);
  font-size: 12px;
  font-weight: 600;
}

.ia-footer .ia-footer-links li:last-child:after {
  display: none;
}

.ai-tabs.nav-tabs {
  list-style: none;
  margin: 0;
  border: none;
  justify-content: flex-start;
  padding: 0;
  column-gap: 48px;
  display: flex;
  flex-direction: row;
}

.ai-tabs li {
  display: inline-block;
  margin-right: 12px;
  position: relative;
}

.ai-tabs.nav-tabs .nav-item {
  position: relative;
  margin-right: 12px;
}

.ai-tabs.nav-tabs .nav-item:last-child {
  margin-right: 0;
}

.ai-tabs.nav-tabs .nav-item button,
.ai-tabs.nav-tabs .nav-item {
  appearance: none;
  background: transparent;
  border: none;
  color: var(--Grey);
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
  padding: 8px 0;
  margin: 0;

  &.active {
    color: var(--b-primary);
  }
}

.ai-tabs li:last-child {
  margin-right: 0;
}

.ai-tabs.nav-tabs .nav-item button:after,
.ai-tabs.nav-tabs .nav-item:after {
  content: "";
  position: absolute;
  bottom: 3px;
  left: 0;
  height: 3px;
  width: 100%;
  border-radius: 3px;
  background-color: none;
}

.ai-tabs.nav-tabs .nav-item button.active:after,
.ai-tabs.nav-tabs .nav-item.active:after {
  background-color: var(--b-primary);
}

.ai-full-height {
  height: 100vh;
}

.global-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
}

.subnavbar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  column-gap: 8px;
  align-items: center;
  height: 100%;
  flex: 1;
}

.SUBITEM,
.SUBITEM-selected {
  display: flex;
  text-align: center;
  color: var(--Grey);
  padding: 8px 16px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  white-space: nowrap;
  border-radius: 4px 4px 0 0;
  border: 1px transparent solid;
  border-bottom: none;
}

.SUBITEM-selected {
  color: var(--black);
  background: var(--white);
  border: 1px var(--d-border) solid;
  border-bottom: none;
}

.SUBITEM:hover {
  color: var(--b-primary);
}

.tabheader {
  display: flex;
  background: var(--bg-gray);
  margin: 0;
  padding: 8px 16px 0;
  border-radius: 4px 4px 0 0;
  color: var(--b-primary);
  cursor: pointer;
  font-size: 14px;
  float: left;
  width: 100%;

  span {
    float: right;
    padding: 0px 8px;
    font-size: 24px;
  }
}

.tabbody {
  border-top: 1px var(--d-border) solid;
  margin-top: -1px;
  width: 100%;
  float: left;
}

.tabbody {
  display: flex;
  flex-direction: column;
}
// .slick-slide{
//   height: auto;
// }
.ai-backtest-container .tab-content .slick-slider,
.ai-date-selection {
  display: flex;
  align-content: center;
  align-items: center;
  column-gap: 8px;
  background: var(--white);
  margin-bottom: 24px;

  .ai-years {
    background: var(--bg-gray);
    border-radius: 4px;
    display: flex;
    gap: 8px;
    padding: 8px;

    button {
      background: none;
      border: none;
      text-transform: uppercase;
      padding: 4px;
      border-radius: 4px;

      &.active {
        background: var(--Navy-Blue);
        color: var(--white);
        box-shadow: 0 4px 14px rgba(24, 39, 75, .08), 0 9px 44px rgba(24, 39, 75, .09);
      }
    }
  }
}

.ai-date-selection .react-datepicker-wrapper {
  max-width: 200px;
}

.ai-backtest-container .ai-backtest-select select {
  appearance: none;
  border: none;
  padding: 0 0 0 0;
  font-size: 24px;
  position: relative;
}

.ai-backtest-container .ai-backtest-select select option {
  font-size: 12px;
}

.ai-backtest-container .ai-backtest-select .ai-backtest-select-arrow {
  position: absolute;
  top: 32px;
  right: 0px;
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("./images/arrowdown.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.ai-backtest-container .ai-backtest-events {
  margin-top: 30px;
}

.ai-backtest-graph {
  width: 100%;
  // overflow: hidden;
  text-align: left;
  // display: flex;
  flex: 1;
}

.ai-backtest-graph img {
  object-fit: cover;
}

.ai-backtest-container .ai-date-selection {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin-top: 14px;
  align-items: center;
  margin: 0;
  justify-content: center;
  column-gap: 24px;
  flex-wrap: wrap;
}

.ai-backtest-container .ai-form-group {
  max-width: 300px;
  width: 100%;
}

.tab-content>div.active {
  display: block;
}

.ai-backtest-slider {
  margin: 20px 0;
}

.ai-backtest-slider .ai-backtest-event {
  display: block;
  text-align: center;
  padding: 14px 0;
}

.ai-backtest-slider .slick-track>div {
  border-right: 1px dotted rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
}

.ai-backtest-slider .slick-track>div:last-child {
  border-right: none;
}

.ai-backtest-event p {
  font-size: 12px;
  margin: 0 0 8px 0;
}

.ai-backtest-event .ai-event-timestamp {
  margin: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}

.ai-slide-next,.ai-slide-prev{
  background-repeat: no-repeat;
  background-size: cover;
  
  background-color: transparent;
  text-indent: -9999px;
  z-index: 10;
  margin-top: -12px;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}
.ai-slide-next{
  border-top: 2px var(--b-grey) solid;
  border-right: 2px var(--b-grey) solid;
}
.slick-prev{
  left: 0px;
}
.slick-next{
  right: -5px;
}
.ai-slide-prev{
  border-bottom: 2px var(--b-grey) solid;
  border-left: 2px var(--b-grey) solid;
  
}


// .ai-slide-prev:hover {
//   background-repeat: no-repeat;
//   background-size: cover;
//   left: 7px;
//   background-color: transparent;
//   text-indent: -9999px;
//   z-index: 10;
//   margin-top: -12px;
// }


.tab-pane {
  display: none;
}

.tab-content>div.active {
  display: block;
}

.ai-title {
  font-size: 18px;
  text-align: left;
}

.react-tooltip {
  .ai-link {
    color: var(--Primary);

    &:hover {
      color: var(--Primary);
    }
  }

  background: #fff !important;
  padding: 0 !important;
}

// Tooltip

/* tooltip styles */
.react-tooltip {
  max-width: 300px !important;
  background: var(--Black) !important;
  z-index: 10000;

  span {
    width: 100% !important;
    white-space: wrap;
    color: var(--White);
    padding: 12px !important;
    font-size: 12px !important;
    line-height: 16px !important;
    max-width: 300px;
    position: relative;
    z-index: 10000;
    display: block;
    font-weight: normal !important;
    text-transform: none !important;
    text-align: left;
  }

  a {
    color: #86b0f4;
    text-decoration: none;

    &:hover {
      color: #4286f4;
    }
  }

  .react-tooltip-arrow {
    border-color: #333;
  }
}

.react-tooltip-arrow::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  z-index: 99;
  display: block;
}

.react-tooltip-up-arrow::before {
  border-top: 10px solid #ccc;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: -10px;
  top: -11px;
}

.react-tooltip-down-arrow::before {
  border-bottom: 10px solid #ccc;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: -10px;
  bottom: -11px;
}

.react-tooltip-right-arrow::before {
  border-right: 10px solid #ccc;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  right: -11px;
  top: -10px;
}

.react-tooltip-left-arrow::before {
  border-left: 10px solid #ccc;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: -11px;
  top: -10px;
}

.arrow-content-tooltip .react-tooltip {
  box-sizing: border-box;
  border: 1px solid gray;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.arrow-content-tooltip .react-tooltip-down-arrow svg {
  transform: translateY(1px);
}

.arrow-content-tooltip .react-tooltip-right-arrow svg {
  transform: rotate(270deg) translateY(-4px) translateX(-4px);
}

.arrow-content-tooltip .react-tooltip-up-arrow svg {
  transform: rotate(180deg) translateY(1px);
}

.arrow-content-tooltip .react-tooltip-left-arrow svg {
  transform: rotate(90deg) translateY(5px) translateX(4px);
}

.react-tooltip-arrow {
  display: none !important;
  background: var(--Secondary) !important;
  // border-left: 6px solid transparent !important;
  // border-right: 6px solid transparent !important;
  // border-top-width: 6px !important;
}

@media screen and (max-width: 680px) {
  .details_wrap .infocard {
    flex-direction: column;
    align-items: flex-start;
  }

  .ai-date-selection {
    flex-direction: column;
  }

  .ai-date-selection p {
    margin: 0;
  }

  .ai-tabs.nav-tabs {
    column-gap: 24px;
  }
}

@media screen and (max-width: 640px) {
  

  .ai-login-form {
    height: auto;
    flex: auto;
  }
}

@media screen and (max-width: 440px) {
  .loginwrapper .ai-login-container .ai-login-form {
    .accounticon:last-child {
      display: none;
    }
  }
}


// drawer css
.drawerbackdrop{
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;  
  background-color:rgba(0, 0, 0, 0.15);  
  z-index: 1099;
}
.drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 300px);
  height: 100%;
  transform: translateX(0);
  background: var(--white);
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  &.popout{
    top: 50%;
    left: 50%;
    width:100%;
    max-width:700px;
    max-height:700px;
    height:90%;
    transform: translate(-50%, -50%);
    box-shadow: 2px 0px 50px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border-top: 1px solid #ddd;
    .drawer-header {
      padding:16px;
    }  
    .drawerbottom{
      padding:16px;
      border-top: 1px solid #ddd;
    }
  }
  .drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 16px 15px 0;
    border-bottom: 1px solid #ddd;
    z-index: 4;
    position: relative;
    background: var(--white);

    h1 {
      font-size: 20px;
      margin: 0;
      color: #333;
      flex: 1;
      text-transform: capitalize;
    }
  }

  .drawercontent {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 48px 24px 16px;
    flex: 1;
    overflow: auto;
    position: relative;
    &.padtop{
      padding-top: 64px;
    }
  }

  .portfolio-info h1 {
    font-size: 16px;
    color: #333;
  }
}



.tab {
  display: flex;
  margin-top: 0;
  gap: 10px;
  padding: 8px 24px;
  background: var(--white);
  box-shadow: 0 4px 14px rgba(24, 39, 75, .08), 0 9px 44px rgba(24, 39, 75, .09);
  border-bottom: 1px solid #ddd;
  width: 100%;
  position: relative;
  z-index: 3;
  &.center{
    justify-content: center;
  }
  &.importportal{
    gap: 64px;
    justify-content: space-evenly;
  }
  button {
    padding: 8px 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    &.impbutton{
      min-width: 100px;
    }

    &:hover {
      background: var(--border);
    }

    &.active {
      background: var(--Navy-Blue);
      color: var(--white);
    }
  }
}

.actions {
  display: flex;
  gap: 16px;

  .close-drawer {
    background: none;
    border: none;
    color: var(--Error);
    font-size: 24px;
  }
}

.action-button {
  padding: 10px 20px;
  background-color: purple;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-button:hover {
  background-color: darkpurple;
}





.close-button:hover {
  background-color: #ddd;
}

.portfolio-section {
  margin-bottom: 1rem;
}

.portfolio-header {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  cursor: pointer;
  padding: 8px 12px;

  .arrow-icon {
    transition: transform 0.3s ease;
    font-size: 18px;
    margin-right: 8px;
  }

  h2 {
    margin: 0;
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .arrow-icon.expanded {
    transform: rotate(90deg);
  }
}


// Override css 

.MuiPaper-root {
  // box-shadow: none !important;
}
th button[aria-label="Move"] {
  display: none;
}
.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked{
  color:var(--Navy-Blue) !important;
}
.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background: var(--d-border) !important;
  opacity: 1 !important;
}
.css-198d61m-MuiTypography-root{
  font-size: 14px !important;
}
.css-julti5-MuiSwitch-root{
  width: 48px !important;
  height: 30px !important;
  padding: 10px 12px !important;
}
.css-jsexje-MuiSwitch-thumb{
  width: 15px !important;
  height: 15px !important;
}
.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase{
  padding:4px;
}


.recharts-tooltip-item-list {
  font-size: 14px;
}

.recharts-tooltip-wrapper {
  box-shadow: 0 8px 28px rgba(24, 39, 75, 0.12), 0 18px 88px rgba(24, 39, 75, 0.14);
  border-radius: 4px !important;
  overflow: hidden;

  .recharts-default-tooltip {
    border: none !important;
    overflow: hidden;
    padding: 0 !important;
    .custom-tooltip{
      border: none;
      padding: 0 !important;
    }
  }
  p.recharts-tooltip-label{
    color: var(--Black);
    font-weight: 700;
    font-size: 14px;
    padding-bottom: 8px;
    border-bottom: 1px var(--d-border) solid;
    margin-bottom: 4px !important;
  }
}

th {
  div.MuiBox-root {
    flex-wrap: nowrap;
    white-space: nowrap;
  }
}

.recharts-default-legend {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  li {
    display: flex !important;
    align-items: center;
    gap: 4px;
  }
}

.MuiBox-root {
  box-shadow: none;
}

.editable-table td input {
  border: 1px var(--border) solid;
}
.collpsehead{
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  
  &::after{
    top: 12px;
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    border-bottom: 1px var(--d-border) solid;
  }
  .cicon{
    display: flex;
    width: 24px;
    height: 24px;
    border: 1px var(--d-border) solid;
    border-radius: 4px;
    justify-content: center;
    background: var(--b-secondary);
    font-size: 18px;
  }
    > span{
      background: #fff;
      position: relative;
      z-index: 1;
      margin-right: 16px;
      &.title{
        border: 1px var(--d-border) solid;
        font-size: 16px;
        padding: 2px 8px;
        font-weight: 500;
        border-radius: 4px;
      }
    }
}
.recharts-tooltip-wrapper{
  background: var(--white);
  padding: 8px 16px;
  .custom-tooltip{
    padding: 0 !important;
    border: none !important;
    p{
      font-size: 14px;
      margin: 8px 0;
    }
  }
}
.ai-error-message{
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: var(--Error);
  margin: 0;
}
.react-datepicker-popper{
  z-index: 99;
}