.profile-content{
    width: 100%;
}
.profiledata{
    background: var(--bg-grey);
    padding:24px 100px;
    display: flex;
    .advisorcard{
        display: flex;
        column-gap: 24px;
        flex: 1;
        .username{
            display: flex;
            flex-direction: column;
            row-gap: 16px;
        }
        h4{
            margin: 0;
            font-size: 24px;
            color: var(--b-primary);
        }
        .designations{
            display: flex;
            column-gap: 24px;
            flex-wrap: wrap;
            a{
                display: flex;
                align-items: center;
                column-gap: 8px;
                span{
                    color: var(--f-navyblue);
                }
            }
        }
    }
}
.profileaction{
    display: flex;
    column-gap: 24px;
}
.profileoption{
    display: flex;
    padding: 32px 100px 100px;
    flex-direction: column;
    row-gap: 48px;
    align-items: center;
    .divider{
        border-bottom: 1px var(--border) solid;
        width: 100%;
    }
}
.planhead{
    display: flex;
    width: 100%;
    padding: 0 0 8px;
    justify-content: space-between;
    margin-bottom: 16px;
    border-bottom: 1px var(--border) solid;
    h3{
        color: var(--Primary);
    }
}
.plan{
    background: var(--white);
    box-shadow: 0 8px 28px rgba(24,39,75,.12) , 0 18px 88px rgba(24,39,75,.14);
    padding: 16px 16px 16px 32px;
    display: flex;
    width: 100%;
    border-radius: 8px;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0;
    .planinfo{
        display: flex;
        align-items: center;
        row-gap: 16px;
        flex: 1;
        flex-wrap: wrap;
        width: 100%;
        h4{
            font-size: 20px;
            color: var(--b-primary);
            margin: 0;
        }
        .point{
            display: flex;
            align-items: center;
            column-gap: 16px;
            width: 33%;
            min-width: 300px;
            &:before{
                content: "";
                background: var(--border);
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 100%;
            }
        }
    }
}
.history{
    display: flex;
    h4{
        display: flex;
        flex: 1;
        font-size: 20px;
        font-weight: normal;
        margin: 0;
    }
    .closeacc{
        display: flex;
        flex: 1;
        flex-direction: column;
        row-gap: 16px;
        h4{
            color: var(--Error);
        }
    }
}


.hero-sectionpricing .context {
  max-width: 650px;
}

.price-sheildCard {
  background-color: #ffffff;
  justify-content: center;
}

.price-sheildCard .innercontent {
  padding: 0;
}

.price-sheildCard .innercontent .context {
  text-align: center;
  width: 100%;
}

.pricecardshield {
  width: 33%;
  
  box-shadow: 0px 20px 40px 0px #7090B01A;
  border-radius: 20px;
  padding: 32px 32px;
  border: 10px solid transparent;
  transition: all .48s ease-in-out;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #ffffff;
}

.pricecardshield:hover {
  border: 10px solid #FCEAEB;
  transform: scale(1.03);

}
.pricing-card {
  column-gap: 32px;
  row-gap: 24px;
  justify-content: center;
}

.in-icon {
  width: 64px;
  height: 64px;
  background: var(--Bgsecondary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-content-investor p {
  line-height: 26px;
  color: #242424;
}

.price-listing {
  margin-top: 24px;
}

.top-heading-in,
.price-top {
  display: flex;
  gap: 16px;
  align-items: center;
}

.top-heading-in h3 {
  color: var(--Primary);
  text-transform: uppercase;
}

.price-number {
  margin: 0;
  font-size: 36px;
  font-weight: 600;
  line-height: 46px;
}

.web-tag {
  padding: 2px 12px;
  font-size: 14px;
  color: var(--Primary);
  font-weight: 500;
  background-color: var(--Bgsecondary);
  border-radius: 8px;
  height: 28px;
  display: flex;
  align-items: center;
}

.price-btm p {
  color: var(--Grey);
}

.card-btn {
  width: 100%;
  margin-top: auto;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

.highlight-text {
  text-align: center;
  color: var(--Grey);
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-transform: capitalize;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.line-right,
.line-left,
.highlight-text p {
  width: 33%;
}

.line-left,
.line-right {
  border: 1px solid #BABAC4
}

.marking-list ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 0;
  flex: 1;
}

.marking-list ul li {
  padding: 8px 16px 8px 36px;
  color: #909090;
  position: relative;
  list-style: none;
  line-height: 26px;
}

.marking-list li:before {
  content: "";
  width: 14px;
  height: 6px;
  position: absolute;
  top: 14px;
  left: 12px;
  background-position: center;
  background-size: contain;
  border-left:2px var(--Primary) solid;
  border-bottom:2px var(--Primary) solid;
  border-radius: 0 0 0 2px;
  transform: rotate(-45deg);
}

.marking-list li:nth-of-type(odd) {
  background: linear-gradient(97.16deg, rgba(229, 139, 118, 0.1) 0%, rgba(229, 139, 118, 0.1) 100%);
  border-radius: 4px;
}

.pricecardshield:hover .marking-list {
  align-self: flex-start;
}

.in-icon.p {
  background: #EFE6ED;
}

.popular-tag {
  padding: 8px 16px;
  background: #ED6663;
  color: #ffffff;
  position: absolute;
  top: 0;
  right: 0px;
  border-bottom-left-radius: 16px;
  font-size: 15px;
  border-top-right-radius: 16px;
  line-height: 25px;
}

.card-content-investor {
  display: flex;
  flex-direction: column;
  
  justify-content: flex-start;
  align-items: flex-start;
}

.in-icon.pro {
  background: linear-gradient(180deg, rgba(229, 139, 118, 0.05) 0%, rgba(96, 3, 76, 0.05) 100%);
}

.cardshield-content {
  display: flex;
}

.cardshield-content p {
  flex: 1;
  min-height: 135px;
}
.ShieldCardcon {
    width: 100% !important;
    display: flex;
    max-width:1440px;
}
.pricecardshield.popular {
    margin: -50px 0;
    border: 10px solid #fceaeb;
    z-index: 2;
}
.card-btn{
    height: 50px;
    line-height: 36px !important;
    text-decoration: none;
    text-align: center;
    background-color: var(--Primary);
    color: #fff !important;
    padding: 8px 16px !important;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    border: none;
    display: inline-block;
    width: 100%;
}
.cancelsub {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 8px;
}
@media (max-width: 1024px) {
    .pricecardshield {
        width: 100%;
    }
    .pricecardshield.popular{
        margin: 0;
    }
    .ShieldCardcon {
        flex-direction: column;
        column-gap: 0;
        width: 100%;
        row-gap: 32px;
    }
}
@media (max-width: 960px) {
    .ShieldCardcon {
        flex-wrap: wrap;
        row-gap: 24px;
        justify-content: center;
    }
}
.fade-out {
  transition: opacity 0.5s ease-out;
  opacity: 0;
}