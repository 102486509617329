.customer-portfolio{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    
    .customer{
      padding: 10px;
      border-radius:4px;
      // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      border: 1px var(--border) solid;
      .portfoliohead{
        display: flex;
        // width: calc(100% - 255px);
        width: 100%;
        .icon{
          background: var(--bg-grey);
          height: 24px;
          border-radius: 100%;
          margin-right: 8px;
          margin-top: -2px;
        }
        .newportfolio{
          color: var(--b-grey);
          display: flex;
          align-items: center;
          align-self: center;
          padding: 0;
          border: none;
          background: none;
          column-gap: 8px;
          font-weight: 600;
          img{
            width: 24px;
            height: 24px;
          }
        }
      }
      h2{
          font-size: 16px;
          color: var(--black);
          font-weight: 600;
          margin-bottom: 0;
          margin-top: 0;
          text-transform: capitalize;
          flex: 1;
          text-align: left;
          align-items: center;
      }
      .portfolios{
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        .portfoliocard{
            display: flex;
            align-items: flex-start;
            margin-bottom: 0;
            column-gap: 24px;
            &:first-child{
              margin-top: 8px;
            }
            @media  (max-width:992px){
              flex-direction: column;
              gap: 8px;
            }
            .portlist{
                width:calc(100% - 200px);
                display: flex;
                border-radius: 8px;
                overflow: auto;
                background: var(--border);
                border-radius: 4px;
                align-items: center;
                flex: 1;
                flex-direction: column;
                row-gap: 0;
                justify-content: center;
                position: relative;
                padding: 1px;
                @media  (max-width:992px){
                  width: 100%;
                }
                // &.active{
                //   border: 1px var(--border) solid;
                //   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                // }
                .clientdata{
                    background: var(--white);
                    width: 100%;
                    overflow: auto;
                    .MuiTableContainer-root{
                      margin: 0;
                    }
                    
                    div.MuiBox-root{
                      flex-wrap: wrap;
                    }
                    th {
                      div.MuiBox-root {
                        flex-wrap: nowrap;
                      }
                    }
                }
                flex-direction: column;
                row-gap: 0;
                .clientdata{
                    background: var(--white);
                    width: 100%;
                }
                .listhead{
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    align-items: center;
                    padding: 8px 4px 8px 10px;
                    &.active{
                      background: var(--white);
                      border-bottom: 1px var(--border) solid;
                    }
                    h3{
                    margin: 0;
                    font-size: 14px;
                    font-weight: 500;
                    display: flex;
                    flex: 1;
                    text-transform: capitalize;
                    }    
                    button{
                        display: flex;
                        background: none;
                        border: none;
                        padding:0;
                        min-height: 32px;
                        min-width: 32px;
                        margin: -2px 0;
                        border-radius: 4px;
                        align-items: center;
                        justify-content: center;
                        &.primary{
                          background: var(--Primary);
                        }
                        &.secondary{
                          background: var(--Secondary);
                        }
                        img{
                            max-width: 24px;
                            max-height: 24px;
                        }
                    }
                }
            }        
            .port_action{
                display: flex;
                // display: none;
                width: 220px;
                justify-content: flex-end;
                column-gap: 8px;
                
                @media  (max-width:992px){
                  width: 100%;
                }
                
                &.open{
                  flex-direction: column; 
                  row-gap: 8px;
                  align-items: flex-end;
                  @media  (max-width:992px){
                    flex-direction: row; 
                  }
                  @media  (max-width:560px){
                    flex-direction: column; 
                  }
                  .ai-btn{width: 100%;}
                }
                .ai-btn{
                  margin-top: 0;
                  &.mhide{
                    @media  (max-width:992px){
                      display: none;
                    }
                  }
                }
            }
        }
      }
      &.active{
        .portfolios{
        }
      }
    }
}

table {
    th {
      color: var(--b-primary);
      text-transform: uppercase;
      font-weight: 600;
    }
  
    th {
       > div.MuiIconButton-root {
        padding: 12px 0 12px 10px;
      }
    }
  
    .link {
      color: var(--b-primary);
      font-weight: 600;
    }
  
    .c-green {
      color: var(--Green);
    }
  
    .c-orange {
      color: var(--Orange);
    }
  
    .c-error {
      color: var(--Error);
    }
}



table-2 {
  border-spacing: 6px;
}

.table_holder img {
  width: auto;
  max-width: none;
}

table::-webkit-scrollbar,
table-2::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--Peach);
  border-radius: 8px;
  box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.54);
  margin-left: 8px;
}

table::-webkit-scrollbar-thumb,
table-2::-webkit-scrollbar-thumb {
  background-color: var(--b-primary);
  border-radius: 8px;
}

table {
  th {
    color: var(--Black);
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    padding: 10px;
  }
}

table-2 th {
  padding: 0 8px 12px 8px;
  padding-bottom: 5px;
  color: #60034c;

  font-weight: 700;
  letter-spacing: 1px;
  border-bottom: 3px solid #f0eef0;
  min-width: 50px;
  white-space: nowrap;
}

table th .stck_table_heading,
table-2 th .stck_table_heading {
  font-family: Inter;
  font-size: 13px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 1px;
  padding: 10px 16px;
  color: #60034c;
}

// table th:nth-child(3),
// table-2 th:nth-child(3),
// table th:nth-child(4),
// table-2 th:nth-child(4),
// table th:nth-child(7),
// table-2 th:nth-child(7) {
//   text-align: right;
// }

table {
  border-spacing:0;
  width: 100%;
    td {
    text-align: right;
    color: var(--b-grey);
    font-size: 13px;
    line-height: 16px;
    padding:10px ;
    font-weight: 500;
    text-align: left;
    button{
      padding: 0;
    }
  }
  &.portfoliotikers{
    td:last-child,th:last-child{
      max-width: 86px;
      width: 86px;
      text-align: center;
    }
  }
}

table-2 td {
  text-align: right;
  color: var(--f-navyblue);

  font-size: 14px;
  width: 5%;
  padding: 10px 0 10px 0;
}

// table td:nth-child(6),
// table-2 td:nth-child(6) {
//   color: #38bb55;
// }

// table td:nth-child(7),
// table-2 td:nth-child(7) {
//   color: #38bb55;
// }


table tbody tr:last-child {
  background: var(--white);
}

.sname_field,
.sname_field:focus,
.sname_field:hover {
  outline: none;
  border: none;
  padding: 0;
  text-align: left;
  color: #60034c;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 16px;
  width: 100%;
  background: transparent;
  border-bottom: 1px solid #989898;
}

table tfoot td:nth-child(3) .sname_field {
  text-align: right;
}

table tfoot td:nth-child(4) {
  text-align: left;
}

table tfoot td:nth-child(4),
table tfoot td:nth-child(5),
table tfoot td:nth-child(6),
table tfoot td:nth-child(7) {
  border-bottom: none;
}

.footer_table {
  border-spacing: 16px 4px;
  align-items: center;
  color: #60034c;
  height: 60px;
  background-color: rgba(96, 3, 76, 0.2);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 30%;
}

.footer_table-2 {
  border-spacing: 16px 4px;
  align-items: center;
  padding-left: 20%;
  padding-right: 20%;
  color: #60034c;
  height: 50px;
  background-color: rgba(96, 3, 76, 0.2);
}

/* .nulla{

} */

@media screen and (max-width: 640px) {
  .footer_table-2 {
    padding: 0;
  }
}

table tbody tr td:nth-child(2),
table tbody tr td:nth-child(3),
table tbody tr td:nth-child(4),
table tbody tr td:nth-child(6),
table tbody tr td:nth-child(7) {
  // font-family: 'Droid_Mono';
}

.tiker {
  display: flex;
  column-gap: 16px;
}

.stocklist {

  th,
  td {
    padding: 16px;
    text-align: center;
  }
}

.details-table-container {
  position: relative;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
}

.portfolio-button-modal-container {
  position: relative;
  display: inline-block; 
}

.portfolio-modal {
  position: absolute;
  z-index: 99999;
  .clientinfo{
    padding: 24px;
  }
}
.dropdown{ 
  .MuiPopover-paper{ 
    background-color: #fff;
    padding: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s;
    transform-origin: top left;
    width: 200px;
    button {
        display: block;
        width: 100%;
        border: none;
        background: none;        
        text-align: left;
        padding: 16px ;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        color: var(--black);
        span{
            flex: 1;
        }
        &:hover {
          background-color:var(--bg-grey);
        }
    }
  }
}

.details_wrap {
  align-items: center;
 

  &.alon {
    float: right;
    justify-content: flex-end;
    margin-top: -78px;
    padding: 24px 32px 16px;
  }
}

.stats-holder .details_wrap {
  padding: 8px;
  grid-column-gap: 0;
  column-gap: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.details_wrap {
  align-items: center;
 

  &.alon {
    float: right;
    justify-content: flex-end;
    margin-top: -78px;
    padding: 24px 32px 16px;
  }
  .shieldtitle{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    h3{margin: 0;}
    input{
      width: auto;
    }
  }
}

.stats-holder .details_wrap {
  padding: 8px;
  grid-column-gap: 0;
  column-gap: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.stats-holder .details_wrap .infocard {
  padding: 16px;
  text-align: center;
  max-width: 300px;
  width: 100%;
}

.stats-holder .details_wrap .infocard p {
  width: 100%;
  text-align: left;
}

.stats-holder .details_wrap .infocard h3 {
  text-align: left;
}

.button_wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
}

.infocard {
  display: flex;
  flex-direction: column;
  &.fix{
    min-width: 200px;
  }

  .infocard{
    margin-top: 0;
  }

  h3,
  label {
    text-transform: uppercase;
    color: var(--b-primary);
    font-size: 14px;
    font-weight: 500;
    margin-top: 0;

    @media screen and (max-width: 680px) {
      min-width: 90px;
    }
  }

  p {
    font-size: 20px;
    line-height: 30px;
    color: var(--Black);
    font-weight: 500;
    margin: 0;

    
  }

  @media screen and (max-width: 680px) {
    flex-direction: row;
    align-items: center;
    column-gap: 24px;
  }
}
.infocard-wrap {
  display: flex;
  column-gap: 24px;
  padding: 8px 0;
}
.details_wrap {
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
}
.buttonbuy{
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  p{
    font-size: 12px;
  }
}

.addticker{
  display: flex;
  column-gap: 16px;
  input{
    max-width: 200px;
    margin: 0;
  }
  button{
    min-width: 36px;
    height: 36px;
    background: var(--b-grey);
    border-color: var(--b-grey);
    color: var(--white);
    border: none;
    border-radius: 4px;
  }
  img {
    width: 36px;
    height: 36px;
  }
}
.deleterow{
  border: none;
  background: none;
}

  

.portfolio-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  line-height: 30px;
  color: var(--Black);
  font-weight: 500;
  padding: 0;
  // height: 0;
  background: var(--border);
}

.portfolio-name {
  flex-grow: 1;
  
}

.portfolio-details {
  display: flex;
  align-items: center;
  column-gap:24px;
  padding: 0 10px;
  // position: relative;
  // z-index: 12;
  // right: 64px;
  // top: 4px;
  // width: 100%;
  padding-right: 16px;
  @media  (max-width:992px){
    flex-wrap: wrap;
  }
  @media  (max-width:768px){
    width: 100%;
    padding: 8px;
    row-gap: 8px;
  }
 
  .switchcontent{
    margin: 0;
    background: rgba(255,255,255,0.8);
    padding: 0 10px;
    border-radius: 4px 4px 0 0;
    @media  (max-width:768px){
      width: 100%;
    }
  }
}
.portfolio-value{
  display: flex;
  flex: 1;
  @media  (max-width:910px){
    width: 80%;
  }
}
.pf-action-button {
  display: inline-flex;
  align-items: center;
  align-self: center;
  padding:8px 4px;
  border: none;
  background: none;
  font-size: 14px;
  background: var(--white);
  border-radius: 4px;
  margin-top: -4px;
  color: var((--b-secondary));
  .arrow-down {
    display: inline-block;
    width: 6px;
    height: 6px;
    transform: rotate(45deg);
    border-bottom: 2px var(--b-secondary) solid;
    border-right: 2px var(--b-secondary) solid;
    margin-top: -4px;
    margin-left: 8px;
  }
}



.popoverContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  box-shadow: 2px 0px 50px rgba(0, 0, 0, 0.15);
}

.popoverButton {
  display: flex;
  width: 100%;
  padding:10px 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  align-items: center;
  font-size: 16px;
  column-gap: 32px;
  span{
    flex: 1;
    text-align: left;
  }
  .product_card_img {
    width: 24px;
    height: 24px;
  }
}

.operations{
  display: flex;
  padding: 8px 0;
  gap: 8px;
  flex-wrap: wrap;
}
.barbutton{display: flex;gap: 8px;}
.MuiTable-root .MuiInput-root input{
  font-size: 14px;
}
.tab.dashboardtab {
  margin: -24px -48px 0;
  width: calc(100% + 96px);
  position: sticky;
  top: -24px;
  padding: 8px 48px;
}