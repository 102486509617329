.MuiInputBase-root {
    padding: 0 !important;
}
.MuiOutlinedInput-notchedOutline {
    border: none !important;
    padding: 0 !important;
    height: auto !important;
}
.MuiInputBase-input {
    max-width: 100% !important;
    overflow-y: auto;   /* Enable vertical scrolling */
    overflow-x: hidden;
}
.MuiFormControl-root label.MuiFormLabel-root{
    margin-top: -7px;
    font-family: 'Inter';
    font-size: 14px;
}
label.MuiFormLabel-root.Mui-focused{
    display: none !important;
}
.MuiAutocomplete-clearIndicator {
    color: rgb(255, 255, 255) !important;
    background: rgb(87, 91, 102) !important;
    border-color: rgb(87, 91, 102) !important;
    visibility: visible !important;
    font-size: 1.5rem;
  
    &:hover {
      background-color: rgb(255, 0, 0) !important;
    }
  }  